import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CohortAnalysis from './components/CohortAnalysis';
import PredictiveModeling from './components/PredictiveModeling';
import TreatmentPatterns from './components/TreatmentPatterns';
import OutcomeAnalysis from './components/OutcomeAnalysis';
import { BarChart, Users, Brain, Activity, Settings, Pill, Stethoscope, Menu, X } from 'lucide-react';
import DrugInteractionAnalysis from './components/DrugInteractionAnalysis';
import ClinicalDecisionSupport from './components/ClinicalDecisionSupport';
import RareDiseaseAIHub from './components/RareDiseaseAIHub';

const queryClient = new QueryClient();

const Sidebar = ({ activeItem, setActiveItem, isOpen, setIsOpen }) => {
  const menuItems = [
    { name: 'Cohort Analysis', icon: <Users size={24} /> },
    { name: 'Predictive Modeling', icon: <Brain size={24} /> },
    { name: 'Treatment Patterns', icon: <Activity size={24} /> },
    { name: 'Outcome Analysis', icon: <BarChart size={24} /> },
    { name: 'Drug Interaction Analysis', icon: <Pill size={24} /> },
    { name: 'Clinical Decision Support', icon: <Stethoscope size={24} /> },
    { name: 'Rare Disease AI Hub', icon: <Stethoscope size={24} /> },
  ];

  return (
    <div className={`bg-gray-800 text-white w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition duration-200 ease-in-out z-20`}>
      <div className="flex justify-between items-center px-4">
        <h2 className="text-2xl font-semibold">Clinera AI</h2>
        <button className="md:hidden" onClick={() => setIsOpen(false)}>
          <X size={24} />
        </button>
      </div>
      <nav className="mt-10">
        {menuItems.map((item) => (
          <a
            key={item.name}
            className={`flex items-center space-x-2 py-2.5 px-4 rounded transition duration-200 ${
              activeItem === item.name
                ? 'bg-blue-600 text-white'
                : 'text-gray-400 hover:bg-gray-700'
            }`}
            onClick={() => {
              setActiveItem(item.name);
              setIsOpen(false);
            }}
          >
            {item.icon}
            <span>{item.name}</span>
          </a>
        ))}
      </nav>
    </div>
  );
};

function App() {
  const [activeItem, setActiveItem] = useState('Cohort Analysis');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex h-screen bg-gray-100 overflow-hidden">
        <Sidebar 
          activeItem={activeItem} 
          setActiveItem={setActiveItem}
          isOpen={isSidebarOpen}
          setIsOpen={setIsSidebarOpen}
        />
        <div className="flex-1 flex flex-col overflow-hidden">
          <header className="bg-white shadow-sm z-10">
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
              <div className="flex items-center">
                <button 
                  className="md:hidden mr-4 text-gray-500 hover:text-gray-600"
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                >
                  <Menu size={24} />
                </button>
                <h1 className="text-2xl font-semibold text-gray-900">{activeItem}</h1>
              </div>
              <div className="flex items-center">
                <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out">
                  New Analysis
                </button>
                <button className="ml-4 text-gray-500 hover:text-gray-600">
                  <Settings size={24} />
                </button>
              </div>
            </div>
          </header>
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
              {activeItem === 'Cohort Analysis' && <CohortAnalysis />}
              {activeItem === 'Predictive Modeling' && <PredictiveModeling />}
              {activeItem === 'Treatment Patterns' && <TreatmentPatterns />}
              {activeItem === 'Outcome Analysis' && <OutcomeAnalysis />}
              {activeItem === 'Drug Interaction Analysis' && <DrugInteractionAnalysis />}
              {activeItem === 'Clinical Decision Support' && <ClinicalDecisionSupport />}
              {activeItem === 'Rare Disease AI Hub' && <RareDiseaseAIHub />}
            </div>
          </main>
        </div>
      </div>
    </QueryClientProvider>
  );
}

export default App;