import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";

const OutcomeAnalysis = () => {
  const [selectedOutcome, setSelectedOutcome] = useState("a1c");

  const fetchOutcomeData = async () => {
    // Mock data for demonstration
    return {
      a1c: {
        distribution: [
          { range: "<6.5%", count: 200 },
          { range: "6.5-7%", count: 300 },
          { range: "7-8%", count: 250 },
          { range: "8-9%", count: 150 },
          { range: ">9%", count: 100 },
        ],
        trend: [
          { month: "Jan", value: 7.5 },
          { month: "Feb", value: 7.3 },
          { month: "Mar", value: 7.1 },
          { month: "Apr", value: 7.0 },
          { month: "May", value: 6.8 },
          { month: "Jun", value: 6.7 },
        ],
      },
      bloodPressure: {
        distribution: [
          { range: "<120/80", count: 250 },
          { range: "120-129/80", count: 300 },
          { range: "130-139/80-89", count: 200 },
          { range: "140-159/90-99", count: 150 },
          { range: ">=160/100", count: 100 },
        ],
        trend: [
          { month: "Jan", systolic: 135, diastolic: 85 },
          { month: "Feb", systolic: 133, diastolic: 84 },
          { month: "Mar", systolic: 130, diastolic: 82 },
          { month: "Apr", systolic: 128, diastolic: 80 },
          { month: "May", systolic: 125, diastolic: 78 },
          { month: "Jun", systolic: 122, diastolic: 76 },
        ],
      },
    };
  };

  const {
    data: outcomeData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["outcomeData", selectedOutcome],
    queryFn: fetchOutcomeData,
  });

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-64">Loading...</div>
    );
  if (error)
    return (
      <div className="text-red-500">An error occurred: {error.message}</div>
    );

  const outcome = outcomeData[selectedOutcome];

  return (
    <div className="space-y-8">
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">
          Outcome Measure Selection
        </h2>
        <select
          value={selectedOutcome}
          onChange={(e) => setSelectedOutcome(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="a1c">HbA1c Levels</option>
          <option value="bloodPressure">Blood Pressure</option>
        </select>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Outcome Distribution</h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={outcome.distribution}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="range" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Outcome Trend</h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={outcome.trend}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              {selectedOutcome === "a1c" ? (
                <Line type="monotone" dataKey="value" stroke="#8884d8" />
              ) : (
                <>
                  <Line type="monotone" dataKey="systolic" stroke="#8884d8" />
                  <Line type="monotone" dataKey="diastolic" stroke="#82ca9d" />
                </>
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default OutcomeAnalysis;
