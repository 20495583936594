import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const TreatmentPatterns = () => {
  const [selectedCondition, setSelectedCondition] = useState('diabetes');

  const fetchTreatmentData = async () => {
    // Mock data for demonstration
    return {
      diabetes: {
        medications: [
          { name: 'Metformin', count: 500 },
          { name: 'Insulin', count: 300 },
          { name: 'Sulfonylureas', count: 200 },
          { name: 'DPP-4 inhibitors', count: 150 },
        ],
        combinations: [
          { name: 'Metformin + Insulin', value: 250 },
          { name: 'Metformin + Sulfonylureas', value: 150 },
          { name: 'Insulin + DPP-4 inhibitors', value: 100 },
          { name: 'Other combinations', value: 50 },
        ],
      },
      hypertension: {
        medications: [
          { name: 'ACE inhibitors', count: 400 },
          { name: 'Beta blockers', count: 350 },
          { name: 'Diuretics', count: 300 },
          { name: 'Calcium channel blockers', count: 250 },
        ],
        combinations: [
          { name: 'ACE inhibitors + Diuretics', value: 200 },
          { name: 'Beta blockers + Diuretics', value: 150 },
          { name: 'ACE inhibitors + Calcium channel blockers', value: 100 },
          { name: 'Other combinations', value: 100 },
        ],
      },
    };
  };

  const { data: treatmentData, isLoading, error } = useQuery({
    queryKey: ['treatmentData', selectedCondition],
    queryFn: fetchTreatmentData,
  });

  if (isLoading) return <div className="flex justify-center items-center h-64">Loading...</div>;
  if (error) return <div className="text-red-500">An error occurred: {error.message}</div>;

  const conditionData = treatmentData[selectedCondition];
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div className="space-y-8">
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Condition Selection</h2>
        <select
          value={selectedCondition}
          onChange={(e) => setSelectedCondition(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="diabetes">Diabetes</option>
          <option value="hypertension">Hypertension</option>
        </select>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Medication Usage</h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={conditionData.medications}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Medication Combinations</h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={conditionData.combinations}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {conditionData.combinations.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TreatmentPatterns;