import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';

const PredictiveModeling = () => {
  const [selectedModel, setSelectedModel] = useState('readmission');

  const fetchPredictiveData = async () => {
    // Mock data for demonstration
    return {
      readmission: {
        probabilities: [
          { risk: 'Low', probability: 0.2 },
          { risk: 'Medium', probability: 0.5 },
          { risk: 'High', probability: 0.3 },
        ],
        timeline: [
          { day: 1, risk: 0.1 },
          { day: 7, risk: 0.2 },
          { day: 14, risk: 0.3 },
          { day: 30, risk: 0.4 },
        ],
      },
      mortality: {
        probabilities: [
          { risk: 'Low', probability: 0.6 },
          { risk: 'Medium', probability: 0.3 },
          { risk: 'High', probability: 0.1 },
        ],
        timeline: [
          { day: 1, risk: 0.05 },
          { day: 7, risk: 0.08 },
          { day: 14, risk: 0.12 },
          { day: 30, risk: 0.15 },
        ],
      },
    };
  };

  const { data: predictiveData, isLoading, error } = useQuery({
    queryKey: ['predictiveData', selectedModel],
    queryFn: fetchPredictiveData,
  });

  if (isLoading) return <div className="flex justify-center items-center h-64">Loading...</div>;
  if (error) return <div className="text-red-500">An error occurred: {error.message}</div>;

  const modelData = predictiveData[selectedModel];

  return (
    <div className="space-y-8">
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Predictive Model Selection</h2>
        <select
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="readmission">30-Day Readmission</option>
          <option value="mortality">Mortality Risk</option>
        </select>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Risk Probabilities</h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={modelData.probabilities}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="risk" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="probability" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Risk Timeline</h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={modelData.timeline}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="risk" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default PredictiveModeling;