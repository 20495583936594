import React from 'react';

const AIThinkingLoader = () => {
  return (
    <div className="flex flex-col items-center justify-center p-8 bg-gray-100 rounded-lg shadow-md">
      <svg className="w-24 h-24 mb-4" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="30" fill="none" stroke="#4F46E5" strokeWidth="8" strokeLinecap="round">
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="50" cy="50" r="15" fill="#4F46E5">
          <animate
            attributeName="r"
            from="15"
            to="20"
            dur="0.8s"
            repeatCount="indefinite"
            keyTimes="0;0.5;1"
            values="15;20;15"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
            calcMode="spline"
          />
        </circle>
      </svg>
      <div className="text-lg font-semibold text-gray-700">Clinera AI is thinking...</div>
      <div className="mt-2 text-sm text-gray-500">Analyzing the scenario and generating recommendations</div>
    </div>
  );
};

export default AIThinkingLoader;
