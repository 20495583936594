import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const CohortAnalysis = () => {
  const [cohortCriteria, setCohortCriteria] = useState({
    diagnosis: '',
    medication: '',
    ageRange: [0, 100],
    gender: ''
  });

  const fetchCohortData = async () => {
    // Mock data for demonstration
    return {
      demographics: {
        ageDistribution: [
          { age: '0-20', count: 50 },
          { age: '21-40', count: 150 },
          { age: '41-60', count: 200 },
          { age: '61+', count: 100 }
        ],
        genderBreakdown: [
          { name: 'Male', value: 250 },
          { name: 'Female', value: 250 },
          { name: 'Other', value: 20 }
        ]
      },
      treatmentPatterns: [
        { medication: 'Med A', count: 150 },
        { medication: 'Med B', count: 100 },
        { medication: 'Med C', count: 200 },
        { medication: 'Med D', count: 50 }
      ],
      outcomes: [
        { name: 'Improved', value: 60 },
        { name: 'No Change', value: 30 },
        { name: 'Worsened', value: 10 }
      ]
    };
  };

  const { data: cohortData, isLoading, error } = useQuery({
    queryKey: ['cohortData', cohortCriteria],
    queryFn: fetchCohortData
  });

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  if (isLoading) return <div className="flex justify-center items-center h-64">Loading...</div>;
  if (error) return <div className="text-red-500">An error occurred: {error.message}</div>;

  return (
    <div className="space-y-8">
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Cohort Criteria</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Diagnosis</label>
            <input 
              type="text" 
              value={cohortCriteria.diagnosis} 
              onChange={(e) => setCohortCriteria({...cohortCriteria, diagnosis: e.target.value})}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter diagnosis"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Medication</label>
            <input 
              type="text" 
              value={cohortCriteria.medication} 
              onChange={(e) => setCohortCriteria({...cohortCriteria, medication: e.target.value})}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter medication"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Age Range</label>
            <div className="flex items-center space-x-4">
              <input 
                type="number" 
                value={cohortCriteria.ageRange[0]} 
                onChange={(e) => setCohortCriteria({...cohortCriteria, ageRange: [parseInt(e.target.value), cohortCriteria.ageRange[1]]})}
                className="w-20 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
              <span>to</span>
              <input 
                type="number" 
                value={cohortCriteria.ageRange[1]} 
                onChange={(e) => setCohortCriteria({...cohortCriteria, ageRange: [cohortCriteria.ageRange[0], parseInt(e.target.value)]})}
                className="w-20 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Gender</label>
            <select 
              value={cohortCriteria.gender} 
              onChange={(e) => setCohortCriteria({...cohortCriteria, gender: e.target.value})}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">All</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
      </div>
      
      {cohortData && (
        <>
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Age Distribution</h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={cohortData.demographics.ageDistribution}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="age" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="count" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white shadow rounded-lg p-6">
              <h2 className="text-xl font-semibold mb-4">Gender Distribution</h2>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={cohortData.demographics.genderBreakdown}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                    >
                      {cohortData.demographics.genderBreakdown.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="bg-white shadow rounded-lg p-6">
              <h2 className="text-xl font-semibold mb-4">Treatment Patterns</h2>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={cohortData.treatmentPatterns}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="medication" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Outcome Analysis</h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={cohortData.outcomes}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {cohortData.outcomes.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CohortAnalysis;