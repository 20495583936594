import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

const DrugInteractionAnalysis = () => {
  const [selectedDrugs, setSelectedDrugs] = useState([]);

  const fetchDrugData = async () => {
    // Mock data for demonstration
    return {
      drugs: [
        { id: 1, name: 'Aspirin' },
        { id: 2, name: 'Lisinopril' },
        { id: 3, name: 'Metformin' },
        { id: 4, name: 'Simvastatin' },
        { id: 5, name: 'Omeprazole' },
      ],
      interactions: [
        { drug1: 1, drug2: 2, severity: 'Moderate', description: 'May decrease antihypertensive effects' },
        { drug1: 1, drug2: 4, severity: 'Minor', description: 'Increased risk of myopathy' },
        { drug1: 2, drug3: 3, severity: 'Minor', description: 'May increase risk of hypoglycemia' },
        { drug1: 3, drug2: 5, severity: 'Moderate', description: 'May decrease absorption of metformin' },
      ],
    };
  };

  const { data: drugData, isLoading, error } = useQuery({
    queryKey: ['drugData'],
    queryFn: fetchDrugData,
  });

  if (isLoading) return <div className="flex justify-center items-center h-64">Loading...</div>;
  if (error) return <div className="text-red-500">An error occurred: {error.message}</div>;

  const handleDrugSelect = (drugId) => {
    if (selectedDrugs.includes(drugId)) {
      setSelectedDrugs(selectedDrugs.filter(id => id !== drugId));
    } else {
      setSelectedDrugs([...selectedDrugs, drugId]);
    }
  };

  const getInteractions = () => {
    return drugData.interactions.filter(interaction => 
      selectedDrugs.includes(interaction.drug1) && selectedDrugs.includes(interaction.drug2)
    );
  };

  return (
    <div className="space-y-8">
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Select Medications</h2>
        <div className="flex flex-wrap gap-2">
          {drugData.drugs.map(drug => (
            <button
              key={drug.id}
              onClick={() => handleDrugSelect(drug.id)}
              className={`px-4 py-2 rounded-full ${
                selectedDrugs.includes(drug.id)
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700'
              }`}
            >
              {drug.name}
            </button>
          ))}
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Drug Interactions</h2>
        {getInteractions().length > 0 ? (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Drug 1</Th>
                <Th>Drug 2</Th>
                <Th>Severity</Th>
                <Th>Description</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getInteractions().map((interaction, index) => (
                <Tr key={index}>
                  <Td>{drugData.drugs.find(d => d.id === interaction.drug1).name}</Td>
                  <Td>{drugData.drugs.find(d => d.id === interaction.drug2).name}</Td>
                  <Td>{interaction.severity}</Td>
                  <Td>{interaction.description}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <p>No interactions found for the selected medications.</p>
        )}
      </div>
    </div>
  );
};

export default DrugInteractionAnalysis;