import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3017';

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const getClinicalDecision = async (scenario) => {
  try {
    const response = await api.post('/api/clinical-decision', { scenario });
    return response.data;
  } catch (error) {
    console.error('Error fetching clinical decision:', error);
    throw error;
  }
};