import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Network, Users, Map, Activity, Microscope } from 'lucide-react';
import * as d3 from 'd3';
import Globe from 'react-globe.gl';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CytoscapeComponent from 'react-cytoscapejs';

const RareDiseaseAIHub = () => {
  const [selectedDisease, setSelectedDisease] = useState('');

  const fetchRareDiseases = async () => {
    // Enhanced mock data
    return [
      { id: '1', name: 'Hutchinson-Gilford Progeria Syndrome', prevalence: '1 in 4 million', geneAffected: 'LMNA' },
      { id: '2', name: 'Fibrodysplasia Ossificans Progressiva', prevalence: '1 in 2 million', geneAffected: 'ACVR1' },
      { id: '3', name: 'Ehlers-Danlos Syndrome', prevalence: '1 in 5,000', geneAffected: 'COL5A1, COL5A2, COL3A1' },
      { id: '4', name: 'Pompe Disease', prevalence: '1 in 40,000', geneAffected: 'GAA' },
    ];
  };

  const { data: rareDiseases, isLoading, error } = useQuery({
    queryKey: ['rareDiseases'],
    queryFn: fetchRareDiseases,
  });

  const handleDiseaseSelect = (diseaseId) => {
    setSelectedDisease(diseaseId);
  };

  if (isLoading) return <div className="flex justify-center items-center h-screen">Loading rare disease data...</div>;
  if (error) return <div className="text-red-500">An error occurred: {error.message}</div>;

  return (
    <div className="p-4 md:p-6 bg-gradient-to-br from-purple-900 via-indigo-900 to-blue-900 min-h-screen text-white">
      <motion.h1 
        className="text-2xl md:text-4xl font-bold mb-4 md:mb-8 text-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Rare Disease AI Hub
      </motion.h1>

      <div className="mb-4 md:mb-8">
        <Select onValueChange={handleDiseaseSelect}>
          <SelectTrigger className="w-full bg-white text-black">
            <SelectValue placeholder="Select a rare disease" />
          </SelectTrigger>
          <SelectContent>
            {rareDiseases.map(disease => (
              <SelectItem key={disease.id} value={disease.id}>
                {disease.name} - {disease.prevalence}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {selectedDisease && (
        <>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mb-4 md:mb-8 p-4 bg-indigo-800 rounded-lg"
          >
            <h2 className="text-xl md:text-2xl font-semibold mb-2">
              {rareDiseases.find(d => d.id === selectedDisease).name}
            </h2>
            <p className="text-sm md:text-base">Prevalence: {rareDiseases.find(d => d.id === selectedDisease).prevalence}</p>
            <p className="text-sm md:text-base">Gene Affected: {rareDiseases.find(d => d.id === selectedDisease).geneAffected}</p>
          </motion.div>

          <Tabs defaultValue="symptom-analyzer" className="w-full">
          <TabsList className="grid w-full grid-cols-2 md:grid-cols-5 bg-indigo-800 overflow-x-auto">
          <TabsTrigger value="symptom-analyzer" className="text-xs md:text-sm">Symptom Analyzer</TabsTrigger>
              <TabsTrigger value="genetic-visualizer" className="text-xs md:text-sm">Genetic Visualizer</TabsTrigger>
              <TabsTrigger value="treatment-navigator" className="text-xs md:text-sm">Treatment Navigator</TabsTrigger>
              <TabsTrigger value="patient-network" className="text-xs md:text-sm">Patient Network</TabsTrigger>
              <TabsTrigger value="trial-recommender" className="text-xs md:text-sm">Trial Recommender</TabsTrigger>
            </TabsList>
            <TabsContent value="symptom-analyzer">
              <SymptomConstellationAnalyzer diseaseId={selectedDisease} />
            </TabsContent>
            <TabsContent value="genetic-visualizer">
              <GeneticMutationVisualizer diseaseId={selectedDisease} />
            </TabsContent>
            <TabsContent value="treatment-navigator">
              <TreatmentPathwayNavigator diseaseId={selectedDisease} />
            </TabsContent>
            <TabsContent value="patient-network">
              <GlobalPatientNetwork diseaseId={selectedDisease} />
            </TabsContent>
            <TabsContent value="trial-recommender">
              <ClinicalTrialRecommender diseaseId={selectedDisease} />
            </TabsContent>
          </Tabs>
        </>
      )}
    </div>
  );
}

const SymptomConstellationAnalyzer = ({ diseaseId }) => {
  const [symptoms, setSymptoms] = useState([]);

  useEffect(() => {
    // Simulated API call to fetch symptom data
    const fetchSymptoms = async () => {
      // Mock symptom data
      const mockSymptoms = [
        { id: 1, name: 'Rapid Aging', severity: 0.9, relatedTo: [2, 3] },
        { id: 2, name: 'Hair Loss', severity: 0.7, relatedTo: [1] },
        { id: 3, name: 'Cardiovascular Problems', severity: 0.8, relatedTo: [1, 4] },
        { id: 4, name: 'Joint Stiffness', severity: 0.6, relatedTo: [3] },
        { id: 5, name: 'Growth Failure', severity: 0.9, relatedTo: [1, 2] }
      ];
      setSymptoms(mockSymptoms);
    };
    fetchSymptoms();
  }, [diseaseId]);

  const elements = symptoms.map(symptom => ({
    data: { id: symptom.id.toString(), label: symptom.name, size: symptom.severity * 20 }
  })).concat(
    symptoms.flatMap(symptom => 
      symptom.relatedTo.map(relatedId => ({
        data: { source: symptom.id.toString(), target: relatedId.toString() }
      }))
    )
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg md:text-xl"><Activity className="inline-block mr-2" /> Symptom Constellation Analyzer</CardTitle>
        <CardDescription className="text-xs md:text-sm">Visualizing complex symptom patterns</CardDescription>
      </CardHeader>
      <CardContent>
        <div style={{ height: '300px', maxWidth: '100%', overflow: 'hidden' }}>
          <CytoscapeComponent
            elements={elements}
            style={{ width: '100%', height: '100%' }}
            layout={{ name: 'cose' }}
            stylesheet={[
              {
                selector: 'node',
                style: {
                  'background-color': '#6366f1',
                  'label': 'data(label)',
                  'width': 'data(size)',
                  'height': 'data(size)',
                  'font-size': '6px',
                  'color': '#ffffff',
                  'text-valign': 'center',
                  'text-halign': 'center',
                  'text-outline-width': 1,
                  'text-outline-color': '#000000'
                }
              },
              {
                selector: 'edge',
                style: {
                  'width': 1,
                  'line-color': '#94a3b8'
                }
              }
            ]}
          />
        </div>
      </CardContent>
    </Card>
  );
};

const GeneticMutationVisualizer = ({ diseaseId }) => {
  const [geneData, setGeneData] = useState(null);

  useEffect(() => {
    // Simulated API call to fetch genetic data
    const fetchGeneticData = async () => {
      // Mock genetic data
      const mockGeneData = {
        geneName: 'LMNA',
        normalSequence: 'ATCGATCGATCG',
        mutatedSequence: 'ATCGTTCGATCG',
        proteinEffect: 'Truncated Lamin A protein',
        mutationPosition: 5
      };
      setGeneData(mockGeneData);
    };
    fetchGeneticData();
  }, [diseaseId]);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg md:text-xl"><Microscope className="inline-block mr-2" /> Genetic Mutation Visualizer</CardTitle>
        <CardDescription className="text-xs md:text-sm">Exploring genetic variations in the {geneData?.geneName} gene</CardDescription>
      </CardHeader>
      <CardContent>
        {geneData && (
          <div className="space-y-4">
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
              <div className="flex-1">
                <h3 className="text-base md:text-lg font-semibold">Normal Sequence</h3>
                <p className="font-mono text-xs md:text-sm break-all">{geneData.normalSequence}</p>
              </div>
              <div className="flex-1">
                <h3 className="text-base md:text-lg font-semibold">Mutated Sequence</h3>
                <p className="font-mono text-xs md:text-sm break-all">
                  {geneData.mutatedSequence.split('').map((base, index) => (
                    <span key={index} className={index === geneData.mutationPosition ? 'text-red-500 font-bold' : ''}>
                      {base}
                    </span>
                  ))}
                </p>
              </div>
            </div>
            <div>
              <h3 className="text-base md:text-lg font-semibold">Protein Effect</h3>
              <p className="text-xs md:text-sm">{geneData.proteinEffect}</p>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const TreatmentPathwayNavigator = ({ diseaseId }) => {
  const [treatmentData, setTreatmentData] = useState([]);

  useEffect(() => {
    // Simulated API call to fetch treatment data
    const fetchTreatmentData = async () => {
      // Mock treatment data
      const mockTreatmentData = [
        { name: 'Initial Assessment', value: 100, efficacy: 0 },
        { name: 'Genetic Counseling', value: 80, efficacy: 20 },
        { name: 'Symptom Management', value: 60, efficacy: 40 },
        { name: 'Clinical Trial Enrollment', value: 40, efficacy: 60 },
        { name: 'Gene Therapy', value: 20, efficacy: 80 },
        { name: 'Long-term Monitoring', value: 0, efficacy: 100 },
      ];
      setTreatmentData(mockTreatmentData);
    };
    fetchTreatmentData();
  }, [diseaseId]);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg md:text-xl"><Map className="inline-block mr-2" /> Personalized Treatment Pathway Navigator</CardTitle>
        <CardDescription className="text-xs md:text-sm">AI-generated treatment roadmap</CardDescription>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={treatmentData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{fontSize: 10}} />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" tick={{fontSize: 10}} />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" tick={{fontSize: 10}} />
            <Tooltip />
            <Legend wrapperStyle={{fontSize: 10}} />
            <Line yAxisId="left" type="monotone" dataKey="value" stroke="#8884d8" name="Treatment Progress" />
            <Line yAxisId="right" type="monotone" dataKey="efficacy" stroke="#82ca9d" name="Expected Efficacy" />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
const GlobalPatientNetwork = ({ diseaseId }) => {
    const [globeData, setGlobeData] = useState([]);
  
    useEffect(() => {
      // Simulated API call to fetch global patient data
      const fetchGlobalPatientData = async () => {
        // Mock global patient data
        const mockGlobeData = [
          { lat: 40.7128, lng: -74.0060, size: 0.5, color: 'red', label: 'New York' },
          { lat: 51.5074, lng: -0.1278, size: 0.3, color: 'blue', label: 'London' },
          { lat: 35.6762, lng: 139.6503, size: 0.4, color: 'green', label: 'Tokyo' },
          { lat: -33.8688, lng: 151.2093, size: 0.2, color: 'yellow', label: 'Sydney' },
        ];
        setGlobeData(mockGlobeData);
      };
      fetchGlobalPatientData();
    }, [diseaseId]);
  
    return (
      <Card>
        <CardHeader>
          <CardTitle className="text-lg md:text-xl"><Network className="inline-block mr-2" /> Global Patient Matching Network</CardTitle>
          <CardDescription className="text-xs md:text-sm">Connecting rare disease patients worldwide</CardDescription>
        </CardHeader>
        <CardContent>
          <div style={{ height: '300px', width: '100%', overflow: 'hidden' }}>
            <Globe
              globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
              pointsData={globeData}
              pointAltitude="size"
              pointColor="color"
              pointLabel="label"
              width={300}
              height={300}
              backgroundColor="rgba(0,0,0,0)"
            />
          </div>
        </CardContent>
      </Card>
    );
  };
  
  const ClinicalTrialRecommender = ({ diseaseId }) => {
    const [trials, setTrials] = useState([]);
  
    useEffect(() => {
      // Simulated API call to fetch clinical trial data
      const fetchClinicalTrials = async () => {
        // Mock clinical trial data
        const mockTrials = [
          { id: 'NCT01234567', title: 'Gene Therapy for HGPS', phase: 'Phase 2', location: 'Boston, MA', matchScore: 0.95 },
          { id: 'NCT23456789', title: 'Novel Drug Trial for Progeria', phase: 'Phase 1', location: 'Paris, France', matchScore: 0.87 },
          { id: 'NCT34567890', title: 'Combination Therapy Study', phase: 'Phase 3', location: 'Tokyo, Japan', matchScore: 0.82 },
        ];
        setTrials(mockTrials);
      };
      fetchClinicalTrials();
    }, [diseaseId]);
  
    return (
      <Card>
        <CardHeader>
          <CardTitle className="text-lg md:text-xl"><Users className="inline-block mr-2" /> AI-Driven Clinical Trial Recommender</CardTitle>
          <CardDescription className="text-xs md:text-sm">Matching patients with suitable clinical trials</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {trials.map((trial) => (
              <div key={trial.id} className="bg-indigo-800 p-3 md:p-4 rounded-lg">
                <h3 className="text-base md:text-lg font-semibold">{trial.title}</h3>
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center mt-2 space-y-2 md:space-y-0">
                  <span className="text-xs md:text-sm">{trial.phase}</span>
                  <span className="text-xs md:text-sm">{trial.location}</span>
                  <span className="text-xs md:text-sm font-bold text-green-400">
                    Match Score: {(trial.matchScore * 100).toFixed(0)}%
                  </span>
                </div>
                <Button className="mt-2 w-full text-xs md:text-sm">View Details</Button>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  };
  
  export default RareDiseaseAIHub;