import React, { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Label } from './ui/label';
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Activity, Brain } from 'lucide-react';
import { getClinicalDecision } from '../services/api';
import AIThinkingLoader from './ui/AIThinkingLoader';

const formatBoldText = (text) => {
  if (!text) return null;
  const parts = text.split(/(\*\*.*?\*\*)/g);
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }
    return part;
  });
};

const ClinicalDecisionSupport = () => {
    const [selectedScenario, setSelectedScenario] = useState('');
    const [customScenario, setCustomScenario] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const fetchScenarios = async () => {
      // Mock data for clinical scenarios
      return [
        { id: '1', name: 'Diabetic patient with uncontrolled blood sugar' },
        { id: '2', name: 'Hypertensive patient with resistant hypertension' },
        { id: '3', name: 'Patient with chronic kidney disease and anemia' },
        { id: '4', name: 'Elderly patient with atrial fibrillation' },
        { id: 'other', name: 'Other (Custom Scenario)' },
      ];
    };
  
    const { data: scenarios, isLoading: scenariosLoading, error: scenariosError } = useQuery({
      queryKey: ['clinicalScenarios'],
      queryFn: fetchScenarios,
    });
  
    const {
        mutate: fetchAIResponse,
        data: aiResponse,
        error: aiResponseError
      } = useMutation({
        mutationFn: getClinicalDecision,
        onMutate: () => {
          setIsLoading(true);
        },
        onSuccess: (data) => {
          setIsLoading(false);
        },
        onError: (error) => {
          setIsLoading(false);
        },
        onSettled: () => {
          setIsLoading(false);
        }
      });
  
      const handleScenarioSelect = (scenarioId) => {
        setSelectedScenario(scenarioId);
        if (scenarioId && scenarioId !== 'other') {
          const scenario = scenarios.find(s => s.id === scenarioId)?.name;
          setIsLoading(true);
          fetchAIResponse(scenario);
        }
      };
      
      const handleCustomScenarioSubmit = () => {
        if (customScenario.trim()) {
          setIsLoading(true);
          fetchAIResponse(customScenario);
        }
      };
      
  
  
    if (scenariosLoading) return <div className="flex justify-center items-center h-64">Loading scenarios...</div>;
    if (scenariosError) return <div className="text-red-500">An error occurred: {scenariosError.message}</div>;
  
  
  return (
    <div className="space-y-8">
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Clinical Decision Support</h2>
        <div className="space-y-4">
          <div>
            <Label htmlFor="scenario-select">Select Clinical Scenario</Label>
            <Select onValueChange={handleScenarioSelect}>
              <SelectTrigger>
                <SelectValue placeholder="Choose a scenario" />
              </SelectTrigger>
              <SelectContent>
                {scenarios.map(scenario => (
                  <SelectItem key={scenario.id} value={scenario.id}>
                    {scenario.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {selectedScenario === 'other' && (
            <div className="space-y-2">
              <Label htmlFor="custom-scenario">Enter Custom Scenario</Label>
              <Input
                id="custom-scenario"
                value={customScenario}
                onChange={(e) => setCustomScenario(e.target.value)}
                placeholder="Describe the clinical scenario..."
              />
              <Button onClick={handleCustomScenarioSubmit}>Submit Custom Scenario</Button>
            </div>
          )}
        </div>
      </div>

      {isLoading && (
  <div className="mt-8">
    <AIThinkingLoader />
  </div>
)}

{aiResponseError && !isLoading && (
  <Alert variant="destructive" className="mt-8">
    <AlertTitle>Error</AlertTitle>
    <AlertDescription>Failed to fetch AI response. Please try again.</AlertDescription>
  </Alert>
)}


{aiResponse && !isLoading && (
        <div className="bg-white shadow rounded-lg p-6 space-y-6 mt-8">
          <Alert>
            <Brain className="h-4 w-4" />
            <AlertTitle>AI-Generated Recommendation</AlertTitle>
            <AlertDescription>{formatBoldText(aiResponse.recommendation)}</AlertDescription>
          </Alert>

          <div>
            <h3 className="text-lg font-semibold mb-2">Recommended Steps:</h3>
            <ul className="list-disc pl-5 space-y-2">
              {aiResponse.steps.map((step, index) => (
                <li key={index}>{formatBoldText(step)}</li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">Rationale:</h3>
            <p>{formatBoldText(aiResponse.rationale)}</p>
          </div>

          <Alert variant="outline">
            <Activity className="h-4 w-4" />
            <AlertTitle>Important Note</AlertTitle>
            <AlertDescription>
              This AI-generated recommendation is for demonstration purposes only. Always consult with a qualified healthcare professional before making medical decisions.
            </AlertDescription>
          </Alert>
        </div>
      )}
    </div>
  );
};

export default ClinicalDecisionSupport;